import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../App.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 题目
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/question.vue')
  },
  // 收藏详情
  {
    path: '/Collection',
    name: 'Collection',
    component: () => import('../views/Collection.vue')
  },
  // 错题详情
  {
    path: '/Wrongtopic',
    name: 'Wrongtopic',
    component: () => import('../views/Wrongtopic.vue')
  },
  // 错题列表
  {
    path: '/ParseList',
    name: 'ParseList',
    component: () => import('../views/ParseList.vue')
  }, 
  // 每日一练
  {
    path: '/everyday',
    name: 'everyday',
    component: () => import('../views/everyday.vue')
  },
  // 试卷模拟练习
  {
    path: '/paper',
    name: 'paper',
    component: () => import('../views/paper.vue')
  },
  // 答题卡
  {
    path: '/Answersheet',
    name: 'Answersheet',
    component: () => import('../views/Answersheet.vue')
  },
  // 单选
  {
    path: '/SingleChoice',
    name: 'SingleChoice',
    component: () => import('../components/SingleChoice.vue')
  },
  // 阅读理解
  {
    path: '/ReadingComprehension',
    name: 'ReadingComprehension',
    component: () => import('../components/ReadingComprehension.vue')
  },
  // 写作
  {
    path: '/writing',
    name: 'writing',
    component: () => import('../components/writing.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
